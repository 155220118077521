<template>
  <ValidationProvider
      :rules="validationRules"
      class="question satisfaction column"
      :class="width"
      tag="div"
      v-slot="{ errors }"
      v-if="!reviewMode">
    <div class="question-text has-text-weight-bold">
      <p class="question-text__code" v-if="showQuestionCode">{{ question.code }} </p>
      <p class="question-text__question" v-html="decodeHtml(question.question[lang])"></p>
      <p class="help is-danger" v-if="errors[0]">{{ errors[0] }}</p>
      <p class="help" v-else>{{ questionHelp }}</p>
    </div>
    <div class="question-content">
      <template v-if="categories">
        <div :key="index" v-for="(category, index) in categories" class="category-content">
          <p v-if="category.display_title && category.answers.length > 0" class="category-title">{{ category.title[lang] || Object.values(category.title)[0] }}</p>
          <b-field
            :key="answer.id"
            v-for="answer in category.answers"
            class="mb-4"
          >
            <b-radio-button
                @keydown.native="keyHandler"
                :disabled="loading"
                :native-value="answer.code"
                :value="value[`${question.qid}`]"
                @input="onChange"
                type="is-primary"
            >
              <span class="option" v-html="answer.answer[lang]"></span>
            </b-radio-button>
          </b-field>
        </div>
      </template>
      <template v-else>
        <b-field class="mb-4">
          <b-radio-button
              @keydown.native="keyHandler"
              :disabled="loading" :key="answer.id"
              :native-value="answer.code"
              :value="value[`${question.qid}`]"
              @input="onChange"
              type="is-primary"
              v-for="answer in question.answers"
          >
            <span class="option" v-html="answer.answer[lang]"></span>
          </b-radio-button>
        </b-field>
      </template>
    </div>
  </ValidationProvider>
  <div class="question" v-else>
    <div class="is-flex is-align-items-center">
      <div class="question-text has-text-weight-bold column p-0">
        <p class="question-text__code" v-if="showQuestionCode">{{ question.code }} </p>
        <p class="question-text__question" v-html="decodeHtml(question.question[lang])"></p>
      </div>
      <b-button type="is-text" @click="$emit('edit')">
        <b-icon icon="pencil" type="is-primary"></b-icon>
      </b-button>
    </div>
    <div class="question-content">
      <template v-if="categories">
        <div :key="index" v-for="(category, index) in categories" class="category-content">
          <p v-if="category.display_title && category.answers.length > 0" class="category-title">{{ category.title[lang] || Object.values(category.title)[0] }}</p>
          <b-radio-button
            @keydown.native="keyHandler"
            :disabled="loading" :key="answer.id"
            :native-value="answer.code"
            :value="value[`${question.qid}`]"
            type="is-primary"
            class="satisfaction-input"
            v-for="answer in category.answers"
          >
            <span class="option" v-html="answer.answer[lang]"></span>
          </b-radio-button>
        </div>
      </template>
      <b-field v-else class="mb-4">
        <b-radio-button
            @keydown.native="keyHandler"
            :disabled="loading" :key="answer.id"
            :native-value="answer.code"
            :value="value[`${question.qid}`]"
            type="is-primary"
            class="satisfaction-input"
            v-for="answer in question.answers"
        >
          <span class="option" v-html="answer.answer[lang]"></span>
        </b-radio-button>
      </b-field>
    </div>
  </div>
</template>
<script>
import { QuestionMixin } from '@/mixins/question'
import { UnFocusMixin } from '@/mixins/unfocus'
import { BlockRandomizationMixin } from "@/mixins/block-randomization";
export default {
  name: 'Satisfaction',
  mixins: [QuestionMixin, UnFocusMixin, BlockRandomizationMixin],
  methods: {
    async keyHandler(e){
      if (e.key === 'Enter'){
        await this.$emit("is-enter");
      }
    },
    async onChange(value) {
      await this.$emit('input', {[this.question.qid]: `${value}`})
      await this.$emit('move-next')
    }
  }
}
</script>
<style lang="scss">
.control {
  padding-top: 10px;
  @include from($desktop) {
    padding-top: 0px;
  }
}

.satisfaction-input .b-radio {
  cursor: default;
  opacity: 1 !important;
}
</style>
